import { Fragment } from "react";

import './services.scss';
import PageBanner from "../page-banner/page-banner";
import { isMobile } from "../../services/isMobileChecker";
import { Link } from "react-router-dom";
import { ServicesComponent } from "../../interfaces";

const Services = ({ numTotalVehicles, numWheelChairVans, showBookNow }: ServicesComponent) => {

    return (
        <Fragment>
            <PageBanner pageName="Our Services"/>
            <div className="contentContainer">
                <div className="taxiContainer">
                    <div className="title">TAXI TRANSPORTATION</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p>
                            Our Taxi Service is our only business, hence the company name. Bonny's Taxi and its 
                            affiliates provide service to the Vancouver Metro Region, The Province of British 
                            Columbia Canada, The Vancouver International Airport from The City of Burnaby, New 
                            Westminster, and the Vancouver Airport. Bonny's and Queen City Taxi Ltd. and its 
                            affiliates have an extensive fleet of licenced Vehicles of which {numWheelChairVans} are 
                            Wheelchair equipped to service the mobility impaired. Taxi service to and from is 
                            possible under contract or customer request on a vica versa or return relationship 
                            when the initial booking is made.
                        </p>

                        <p>
                            After reserving your taxi, hop in and direct your licensed and skilled driver wherever 
                            you wish. Upon arrival at your destination, you can pay via VISA, Mastercard, American 
                            Express, En Route or cash and be on your way.
                        </p>
                    </div>
                </div>

                <div className="PreDateTaxiContainer">
                    <div className="title">PRE-DATED TAXI SCHEDULING </div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p>
                            Bonny's Taxi can book cab rides for anytime in the future (<i><b>except New Year's Eve, severe weather 
                            conditions or unforseen circumstances like a skytrain malfunction</b></i>). Don't wait until the very last 
                            minute to wake up and call a cab. Book a cab the night before and we'll be there to pick you up at a 
                            specific time. All you need to do is call our dispatch numbers listed <Link to='/contacts' className="contentLink">here</Link>
                            {showBookNow  && (
                                <Fragment> or opt in to using our book a ride service found <Link to='/bookings' className="contentLink">here</Link>.</Fragment>
                            )}

                            {!showBookNow && (<Fragment>.</Fragment>)}
                        </p>
                    </div>
                </div>

                <div className="deliveryServiceContainer">
                    <div className="title">DELIVERY AND COURIER</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p>
                            Bonny's delivers people everywhere, but, what about packages? Since our drivers are travelling the Lower Mainland anyway, 
                            Bonny's and it's affiliates offer a restricted deliver/courier service. We can deliver packages just as easily as 
                            we deliver people. This saves you the hassle of travelling out to deliver a single package and then travelling all 
                            the way back.
                        </p>

                        <p>
                            <i><b>We can pickup and deliver any parcel, food order, beverage, or small package for a minimum rate of $20 or 
                            what is greater on the meter.</b></i>
                        </p>
                    </div>
                </div>

                <div className="toursContainer">
                    <div className="title">TOURS</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p>
                            Our drivers are more than willing to show you around our wonderful city. After all, the taxi industry is dependant upon 
                            tourism so our drivers need to know the details on current tourist information.  Similar to the taxi service, after you 
                            call the dispatch number and give us your name and where you want to be picked up, a taxi will pick you up and after 
                            the driver describes a variety of sites to see, the driver will take you where ever you wish to go.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Services;
