import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "../../services/isMobileChecker";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './nav-bar.scss';
import { NavBarComponent } from "../../interfaces";

const NavBar = ({ showBookNow }: NavBarComponent) => {
    const { pathname } = useLocation();

    const getLinkClass = (route: string, isDropdown: boolean = false) => {
        if (isMobile.any()) {
            return route === pathname ? "mobileNavLink active" : "mobileNavLink";
        }

        if (isDropdown) {
            return route === pathname ? "dropdownOption active" : "dropdownOption";
        } else {
            return route === pathname ? "route active" : "route";
        }
    };

    const getDropdownClass = () => {
        const isDropDownActive = (pathname === "/fleet" || pathname === "/rates" || pathname === "/zones" || pathname === "/links");
        return isDropDownActive ? "route dropdownSelected" : "route";
    };

    return (
        <Fragment>
            {!isMobile.any() && (
                <div className="desktopContainer">
                    <div className="navbar">
                        <div className="container">
                            <div className="links">
                                <Link to="/" className={getLinkClass("/")}>Home</Link>
                                <Link to="/about" className={getLinkClass("/about")}>About Us</Link>
                                <Link to="/services" className={getLinkClass("/services")}>Our Services</Link>
                                <Link to="/fleet" className={getLinkClass("/fleet")}>Fleet Lineup</Link>
                                <Link to="/rates" className={getLinkClass("/rates")}>Rates</Link>
                                <Link to="/contacts" className={getLinkClass("/contacts")}>Contact Us</Link>                                
                            </div>

                            {showBookNow && (
                                <div className="bookRideLink">
                                    <Link to="/bookings" className="bookRideRoute">Book a Ride</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isMobile.any() && (
                <Fragment>
                    <div className="mobileContainer">
                        <Navbar collapseOnSelect expand="sm" bg="light">
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav>
                                        <Link className={getLinkClass("/")} to="/">Home</Link>
                                        <Link className={getLinkClass("/about")} to="/about">About Us</Link>
                                        <Link className={getLinkClass("/services")} to="/services">Our Services</Link>
                                        <Link className={getLinkClass("/fleet")} to="/fleet">Fleet Lineup</Link>
                                        <Link className={getLinkClass("/rates")} to="/rates">Rates</Link>
                                        <Link className={getLinkClass("/contacts")} to="/contacts">Contact Us</Link>
                                        {showBookNow && <Link className={getLinkClass("/bookings")} to="/bookings">Book a Ride</Link>}
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
};

export default NavBar;