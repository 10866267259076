import { Fragment } from "react";
import BurnabyPhoto from '../../assets/burnaby.jpg';
import AppStorePhoto from '../../assets/Apple app store logo.png';
import GoogleStorePhoto from '../../assets/Google play store logo.png';
import './home-page.scss';
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../services/isMobileChecker";
import { HomeComponent } from "../../interfaces";

const HomePage = ({ showBookNow }: HomeComponent) => {
    const navigate = useNavigate();

    const onClickBookNow = () => {
        navigate("/bookings");
    };

    const openInNewTab = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <Fragment>
            <div className="containers">
                <div className="photoContainer">
                    <img src={BurnabyPhoto} alt="" />
                    <div className="imageOverlay">
                        <div className="messageContainer">
                            <div className="messageBlob">
                                <div className="title">WELCOME TO BONNY'S TAXI</div>
                                <div className="message"><p>Request a cab from us and let us help you make your journey a simple and comfortable one.</p></div>
                                <div className={`buttons ${isMobile.any() ? "mobileView" : ""}`}>
                                    {showBookNow && <div className="bookNow" onClick={onClickBookNow}><p>Book now</p></div>}    
                                    <div className="appStore">
                                        <img 
                                            src={AppStorePhoto} alt=""
                                            onClick={() => openInNewTab("https://apps.apple.com/ca/app/bonnys-taxi/id6476135180")} />
                                    </div>

                                    <div className="googleStore">
                                        <img 
                                            src={GoogleStorePhoto} alt=""
                                            onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.taxicaller.BonnysTaxi.app")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="recordingNote">Note: All calls are recorded for training and quality assurance.</div>

            </div>
        </Fragment>
    );
};

export default HomePage;