import { Fragment } from 'react';
import PageBanner from '../page-banner/page-banner';
import { isMobile } from '../../services/isMobileChecker';

import './about.scss';

const About = () => {

 
    return (
        <Fragment>
            <PageBanner pageName='About Us' />
            <div className="contentContainer">
                <div className='whoAreWe'>
                    <div className="title">WHO ARE WE?</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p>
                            Operating since 1943, with service in and from Burnaby and Vancouver airport, 
                            Bonny's Taxi is Burnaby's largest taxi and ride-hailing service company. Being
                            affiliated with the following three companies, Queen City Taxi, BBY Elite and 
                            Capitol Hill Taxi, they help us to improve name recognition in our service area
                            and grant each fleet the ability to centralize our service more that what it could
                            be alone.
                        </p>

                        <p>
                            The company itself is comprised of numerous shareholders, most of whom are the actual drivers 
                            who own and operate their own taxis. By incorporating our drivers as our 
                            shareholders, it helps to foster a team effort mentality amongst all of us here at the company.
                        </p>

                        <p>
                            In regards to our affiliations with the three companies previously mentioned: 
                            
                            <br />
                            <b className={`${!isMobile.any() ? "tabbedList" : ""}`}>Queen City Taxi </b>
                            has its primary service within the New Westminister area
                            
                            <br />
                            <b className={`${!isMobile.any() ? "tabbedList" : ""}`}>Bonny's Taxi </b>
                            has its primary service area in Burnaby
                        </p>
                    </div>
                
                </div>
            </div>
        </Fragment>
    );
};

export default About;