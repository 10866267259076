import { BrowserRouter , Routes, Route } from "react-router-dom"; 
import HomePage from './components/home/home-page';
import NavBar from "./components/navbar/nav-bar";
import Header from "./components/header/header";
import NoPage from "./components/no-page/no-page";
import About from "./components/about-us/about";
import Services from "./components/services/services";
import Fleet from "./components/fleet/fleet";
import Rates from "./components/rates/rates";
import Contact from "./components/contact/contact";
import Bookings from "./components/bookings/bookings";
import './App.scss';

const App = () => {
  const addressNavFormat = "5759 Sidley St|Burnaby, BC";
  const mainOfficePhone = "+1 (604) 435-6655";
  const showBookNow = false;
  const numWheelChairVans = 34;
  const numTotalVehicles = 206;
  const tariffUpdateDate = "December 27, 2023";
  
  return (
    <BrowserRouter>
      <Header address={addressNavFormat} mainOfficePhone={mainOfficePhone}></Header>
      <NavBar showBookNow={showBookNow}></NavBar>
      <Routes>
        <Route path='' element={ <HomePage showBookNow={showBookNow} /> } />
        <Route path='/about' element={ <About /> } />
        
        <Route path='/services' element={ 
          <Services 
            numTotalVehicles={numTotalVehicles} 
            numWheelChairVans={numWheelChairVans} 
            showBookNow={showBookNow} /> 
        } />
        
        <Route path='/fleet' element={ 
          <Fleet 
            numTotalVehicles={numTotalVehicles} 
            numWheelChairVans={numWheelChairVans} /> 
        } />

        <Route path='/rates' element={ <Rates tariffUpdateDate={tariffUpdateDate} /> } />
        <Route path='/contacts' element={ <Contact /> } />
        {showBookNow && <Route path='/bookings' element={ <Bookings /> } />}
        <Route path="*" element={ <NoPage /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
