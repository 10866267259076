import { Fragment } from "react";
import PageBanner from "../page-banner/page-banner";
import { isMobile } from "../../services/isMobileChecker";
import { FleetComponent } from "../../interfaces";
import SmallTaxi from '../../assets/small taxi.jpg';
import ThreeTaxis from '../../assets/3 taxis.jpg';
import TaxiVan from '../../assets/van taxi.jpg';
import TaxiCar from '../../assets/car taxi.jpeg';

import './fleet.scss';

const Fleet = ({ numTotalVehicles, numWheelChairVans }: FleetComponent) => {

    return (
        <Fragment>
            <PageBanner pageName="Fleet Lineup"/>
            <div className="contentContainer">
                <div className="fleetContainer">
                    <div className="title">Our Vehicles</div>
                    <div className={`${!isMobile.any() ? "content fleetDisplay" : "content mobileContent mobileFleetDisplay"}`}>
                        <div className="leftPanel">
                            <p>
                                We here at Bonny's Taxi and its affiliates are proud of our company's ability to guarantee our 24/7 high availability 
                                of our taxis. This is achieved mainly through our combined fleet  of {numTotalVehicles} vehicles, out of which, {numWheelChairVans} are
                                wheelchair accessible.
                            </p>

                            {/* <p>
                                Amongst our affiliated fleets, <b>Queen City Taxi</b> serves the New Westminister Division, <b>Capitol City Taxi</b> serves 
                                North Burnaby and <b>Select Taxi</b> alongside <b>BBY Elite</b> serve all of Burnaby alongside Bonny's Taxi.
                            </p> */}
                        </div>

                        <div className="rightPanel">
                            <img src={SmallTaxi} alt="" />
                            <img src={ThreeTaxis} alt="" />
                            <img src={TaxiVan} alt="" />
                            <img src={TaxiCar} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Fleet;