import { Fragment } from "react";

import './rates.scss';
import PageBanner from "../page-banner/page-banner";
import { isMobile } from "../../services/isMobileChecker";
import { RatesComponent } from "../../interfaces";
import { Link } from "react-router-dom";

const Rates = ({ tariffUpdateDate }: RatesComponent) => {

    const openYVRPage = () => {
        window.open("https://www.yvr.ca/en/passengers/transportation/taxis", "_blank");
    };

    return (
        <Fragment>
            <PageBanner pageName="Rates"/>
            <div className="contentContainer">
                <div className="fleetContainer">
                    <div className="title">
                        Current Tariffs
                        <div className="note">Updated as of {tariffUpdateDate}</div>    
                    </div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <p className="rates"><b>Initial Flag: </b>$3.75</p>
                        <p className="rates"><b>Rate/Km: </b>$2.18</p>
                        <p className="rates"><b>Waiting Time: </b>$38.93/hr</p>

                        <p>
                            <b>Note: </b>If transporting any item or items that does not fit in the trunk of a mid-sized 
                            sedan-styled taxi, then a van taxi can be ordered with an extra $10 surcharge.
                        </p>
                        <p><b>Note: </b>For information regarding the YVR flat rates, click <span className="contentLink" onClick={openYVRPage}>here</span>.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Rates;
