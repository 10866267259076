import { Fragment } from 'react';
import { PageBannerComponent } from '../../interfaces';
import BannerImg from '../../assets/page banners.jpg';
import './page-banner.scss';

const PageBanner = ({ pageName }: PageBannerComponent) => {
    return (
        <Fragment>
            <div className="banner">
                <div className="imageContainer">
                    <img src={BannerImg} alt="" />
                    <div className="bannerShade"></div>
                </div>
                
                <div className="textContainer">
                    <div className="pageName">{pageName.toUpperCase()}</div>
                </div>
            </div>
        </Fragment>
    )
};

export default PageBanner;