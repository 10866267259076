import axios from 'axios';
import { MailPayload } from '../interfaces';

const isDevelopment = window.location.hostname === "localhost";
const hostUrl = isDevelopment ? "http://localhost:8080" : "https://bonnystaxi.com/";

export const SendMail = async (mailPayload: MailPayload) => {       
    return await axios.post(`${hostUrl}/mail`, mailPayload, {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
    });
};

export const GetPlacesApiKey = async () => {
    try {
        let response = await axios.get<string>(`${hostUrl}/places-api-key`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        });

        return response.data;
    } catch (error: any) {
        console.error(error.message);
        return "";
    }
};