import { Fragment } from 'react';

import './contact.scss';
import PageBanner from '../page-banner/page-banner';
import { isMobile } from '../../services/isMobileChecker';

const Contact = () => {
    return (
        <Fragment>
            <PageBanner pageName="Contact Us"/>
            <div className="contentContainer">
                <div className="mailingAddressContainer">
                    <div className="title">Mailing Address</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <div className="address">
                            5759 Sidley St <br />
                            Burnaby, BC <br />
                            Canada <br />
                            V5J 5E6
                        </div>
                    </div>
                </div>

                <div className="officeNumbersContainer">
                    <div className="title">Dial-In Phone Numbers</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <div className="phoneNumbers">
                            {/* <p><b>Accounting: </b> +1 (604) 412-0280</p>
                            <p><b>Administration: </b> +1 (604) 435-8235</p> */}
                            <p><b>Burnaby: </b> +1 (604) 435-6655</p>
                            <p><b>New Westminister: </b> +1 (604) 526-1166</p>
                            {/* <p><b>Coquitlam: </b> +1 (604) 931-3333</p> */}
                            <p><b>Fax: </b> +1 (604) 435-6757</p>
                        </div>
                    </div>
                </div>

                <div className="directContactLinesContainer">
                    <div className="title">Direct Contact Lines</div>
                    <div className={`${!isMobile.any() ? "content" : "content mobileContent"}`}>
                        <div className="directLines">
                            <p><b><a href="mailto:emon@bonnystaxi.com">General Manager</a></b>: +1 (604) 412-0281</p>
                            <p><b><a href="mailto:accounts@bonnystaxi.com">Accounts Receivable</a></b>: +1 (604) 412-0280</p>
                            <p><b><a href="mailto:supervisor@bonnystaxi.com">Driver/Customer Service</a></b>: +1 (604) 435-8235</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Contact;